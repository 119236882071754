import React from "react";
import PropTypes from "prop-types";
import paths from "utils/paths";
import { FEATURED_REGION_SHAPE } from "constants/propTypes";
import ImageGrid from "components/ImageGrid";

const RegionImageGrid = ({ regions }) => {
  const regionMap = new Map();
  regionMap.set("Alaska", "alaska.jpg");
  regionMap.set("Canada & New England", "canada-new-england.jpg");
  regionMap.set("Caribbean", "caribbean.jpg");
  regionMap.set("Hawaii", "hawaii.jpg");
  regionMap.set("Mediterranean", "mediterranean.jpg");
  regionMap.set("Mexico", "mexico.jpg");

  let serializedRegions = regions.map((region) => ({
    href: paths.cannedSearch(region.urlname).canonicalUrl,
    src: `/region-map/${`${regionMap
      .get(region.name)
      .substr(0, regionMap.get(region.name).lastIndexOf("."))}.webp`}`,
    srcFallback: `/region-map/${regionMap.get(region.name)}`,
    heading:
      region.name === "Canada & New England"
        ? `Canada &<br /> New England`
        : region.name,
    sailingCount: region.number_of_sailings,
    lowestPrice: region.lowest_price,
  }));

  serializedRegions = serializedRegions.map((region, index) => {
    if (index === 2) {
      return { ...region, rows: 2, smallCols: 2 };
    }
    if (index === 3) {
      return { ...region, smallRows: 2 };
    }
    if (index === 4) {
      return { ...region, cols: 2 };
    }

    return region;
  });

  return <ImageGrid items={serializedRegions} />;
};

RegionImageGrid.propTypes = {
  regions: PropTypes.arrayOf(FEATURED_REGION_SHAPE),
};

export default RegionImageGrid;
